var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { navigateToUrl } from 'single-spa';
import createAuth0Client from '@auth0/auth0-spa-js';
import { IdentityUserManager, setUserManager, } from '@uipath/auth-core';
/**
 * AuthHandler class manages authentication flow for both Identity and Auth0.
 */
export class AuthHandler {
    /**
     * @param startApp - Callback function to start the application
     */
    constructor(startApp) {
        this.startApp = startApp;
        this.redirectKey = 'uipath.root.redirect-to';
        this.im = new IdentityUserManager(this.identityConfig(window.location.pathname.split('/')[1]));
    }
    /**
     * Generates identity configuration based on organization name
     * @param orgName - Organization name
     * @returns IdentityUserManagerSettings
     */
    identityConfig(orgName) {
        return {
            authority: `${window.__ROOT_CONFIG_ENV__.PORTAL_ORIGIN}/identity_`,
            redirect_uri: `${window.location.origin}/${orgName}/portal_/loginsuccess`,
            silent_redirect_uri: `${window.location.origin}/portal_/loginsuccess`,
            post_logout_redirect_uri: `${window.location.origin}/portal_/cloudrpa`,
            acr_values: `tenantName:${orgName}`,
        };
    }
    /**
     * Initializes Auth0 client
     */
    initAuth0Client() {
        return __awaiter(this, void 0, void 0, function* () {
            this.auth0Client = yield createAuth0Client({
                domain: `${window.__ROOT_CONFIG_ENV__.AUTH0_DOMAIN}`,
                client_id: `${window.__ROOT_CONFIG_ENV__.PORTAL_GLOBAL_APPLICATION_CLIENT_ID}`,
                cacheLocation: 'localstorage',
                redirect_uri: `${window.location.origin}/portal_/authCallback`,
                audience: `${window.__ROOT_CONFIG_ENV__.PORTAL_GLOBAL_APPLICATION_AUDIENCE}`,
                scope: 'openid profile email read:current_user update:current_user_metadata',
            });
        });
    }
    /**
     * Handles authentication flow based on current state
     */
    handleAuth() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isIdentitySuccessLogin()) {
                yield this.handleIdentitySuccessLogin();
            }
            else if (this.isAuth0RedirectCallback()) {
                yield this.handleAuth0RedirectCallback();
            }
            else if (this.isGlobalAppLogin()) {
                yield this.handleGlobalAppLogin();
            }
            else {
                yield this.handleDefaultScenario();
            }
        });
    }
    /**
     * Checks if current state is Identity success login
     * @returns boolean
     */
    isIdentitySuccessLogin() {
        return !!window.sessionStorage.getItem(this.redirectKey) && location.pathname.endsWith('/portal_/loginsuccess');
    }
    /**
     * Handles Identity success login scenario
     */
    handleIdentitySuccessLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.im.signinCallback();
            if (user) {
                const redirectTo = window.sessionStorage.getItem(this.redirectKey);
                window.sessionStorage.removeItem(this.redirectKey);
                setUserManager(this.im);
                this.startApp(true);
                navigateToUrl(redirectTo, { replace: true });
            }
        });
    }
    /**
     * Checks if current state is Auth0 redirect callback
     * @returns boolean
     */
    isAuth0RedirectCallback() {
        return location.pathname.includes('/portal_/authCallback');
    }
    /**
     * Handles Auth0 redirect callback scenario
     */
    handleAuth0RedirectCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const { appState } = yield this.auth0Client.handleRedirectCallback();
            this.startApp(true);
            if (appState === null || appState === void 0 ? void 0 : appState.targetUrl) {
                navigateToUrl(appState.targetUrl);
            }
        });
    }
    /**
     * Checks if current state is global app login
     * @returns boolean
     */
    isGlobalAppLogin() {
        return location.pathname.startsWith('/portal_') || location.pathname === '/';
    }
    /**
     * Handles global app login scenario
     */
    handleGlobalAppLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.auth0Client.getUser();
            if (!user) {
                yield this.auth0Client.loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
            }
            else {
                this.startApp(false);
            }
        });
    }
    /**
     * Handles default scenario when no other conditions are met
     */
    handleDefaultScenario() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.im.getUser();
            if (!user) {
                let path = window.location.pathname + window.location.search + window.location.hash;
                if (!window.location.pathname.endsWith('_')) {
                    path += path.endsWith('/') ? 'portal_' : '/portal_';
                }
                window.sessionStorage.setItem(this.redirectKey, path);
                yield this.im.signinRedirect();
            }
            else {
                setUserManager(this.im);
                this.startApp(true);
            }
        });
    }
}
/**
 * Initializes and runs the AuthHandler
 * @param startApp - Callback function to start the application
 */
export const initAuthHandler = (startApp) => __awaiter(void 0, void 0, void 0, function* () {
    const authHandler = new AuthHandler(startApp);
    yield authHandler.initAuth0Client();
    yield authHandler.handleAuth();
});
